<template>
  <b-sidebar
    id="sidebar-right"
    bg-variant="white"
    :visible="isSidebarActive"
    right
    backdrop
    shadow
    title="Sidebar"
    sidebar-class="sidebar-lg"
    no-header
    @hidden="
      resetForm();
      $refs.addIngredientsForm.reset();
    "
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between p-2">
        <h4 class="">Add Ingredients</h4>
        <feather-icon class="cursor-pointer" icon="XIcon" @click="hide" />
      </div>
      <div class="px-2 mt-2">
        <validation-observer ref="addIngredientsForm">
          <b-form @submit.prevent="submitForm">
            <b-row>
              <!-- first name -->

              <b-col cols="12">
                <b-form-group label="Ingredient Name" label-for="name">
                  <validation-provider
                    #default="{ errors }"
                    name="Ingredient Name"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="name"
                      placeholder="Ingredient Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Price" label-for="price">
                  <validation-provider
                    #default="{ errors }"
                    name="Ingredient Price"
                    rules="required"
                  >
                    <b-form-input
                      id="price"
                      v-model="price"
                      placeholder="Price"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12" class="text-end">
                <b-button
                  type="submit"
                  requi
                  variant="primary"
                  class="mr-1 mt-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BAvatar,
  BListGroup,
  BListGroupItem,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import store from "@/store/index";
import { ref, onUnmounted } from "@vue/composition-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import useIngredientsList from "./useIngredientsList";
import { required, integer } from "@validations";
import ingredientsStoreModule from "../ingredientsStoreModule";
export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BSidebar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,

    ValidationProvider,
    ValidationObserver,
    required,
    integer,
  },
  data() {
    return {
      price: null,
      name: null,
      serverSideErrors: null,
    };
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    submitForm() {
      this.$refs.addIngredientsForm.validate().then(async (success) => {
        if (success) {
          let formData = {
            name: this.name,
            price: this.price,
          };
          var result = await this.addIngredients(formData);
          if (result == true) {
            this.$emit("refetch-data");
            this.$emit("update:is-sidebar-active", false);
          }
        }
      });
    },
    resetForm() {
      this.name = "";
      this.price = "";
    },
  },
  setup() {
    // Register module
    const APP_STORE_MODULE_NAME = "master-ingredients";

    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, ingredientsStoreModule);

    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const { addIngredients } = useIngredientsList();

    return {
      addIngredients,
    };
  },
};
</script>
