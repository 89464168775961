var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"sidebar-right","bg-variant":"white","visible":_vm.isSidebarActive,"right":"","backdrop":"","shadow":"","title":"Sidebar","sidebar-class":"sidebar-lg","no-header":""},on:{"hidden":function($event){_vm.resetForm();
    _vm.$refs.addIngredientsForm.reset();},"change":function (val) { return _vm.$emit('update:is-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between p-2"},[_c('h4',{},[_vm._v("Add Ingredients")]),_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"XIcon"},on:{"click":hide}})],1),_c('div',{staticClass:"px-2 mt-2"},[_c('validation-observer',{ref:"addIngredientsForm"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ingredient Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Ingredient Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","placeholder":"Ingredient Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Price","label-for":"price"}},[_c('validation-provider',{attrs:{"name":"Ingredient Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"price","placeholder":"Price"},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"text-end",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1 mt-1",attrs:{"type":"submit","requi":"","variant":"primary"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }